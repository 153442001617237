import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Select, MenuItem } from "@mui/material";
// components
import { BaseOptionChart } from "../../../components/chart";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/redux/hooks";
import { selectUserEvents } from "../../../core/redux/features/stats/statsSlice";

// ----------------------------------------------------------------------

export default function AppWebsiteViews() {
  const [projection, setProjection] = useState("yearly");
  const [chartLabelsState, setChartLabelsState] = useState<any>();
  const [chartDataState, setChartDataState] = useState<any>();

  const userEvents = useAppSelector(selectUserEvents);

  const allApplications = userEvents.allApplications;
  const allDates = allApplications.map((i) => i.date).sort();

  useEffect(() => {
    if (projection === "yearly") {
      setChartLabelsState(allDates);
      setChartDataState([
        {
          name: "Applications",
          type: "area",
          fill: "gradient",
          data: allApplications.map((i) => i.count),
        },
      ]);
    } else if (projection === "90-days") {
      setChartLabelsState(allDates.slice(-90));
      setChartDataState([
        {
          name: "Applications",
          type: "area",
          fill: "gradient",
          data: allApplications.map((i) => i.count).slice(-90),
        },
      ]);
    } else if (projection === "30-days") {
      setChartLabelsState(allDates.slice(-30));
      setChartDataState([
        {
          name: "Applications",
          type: "area",
          fill: "gradient",
          data: allApplications.map((i) => i.count).slice(-30),
        },
      ]);
    } else if (projection === "14-days") {
      setChartLabelsState(allDates.slice(-14));
      setChartDataState([
        {
          name: "Applications",
          type: "area",
          fill: "gradient",
          data: allApplications.map((i) => i.count).slice(-14),
        },
      ]);
    } else if (projection === "7-days") {
      setChartLabelsState(allDates.slice(-7));
      setChartDataState([
        {
          name: "Applications",
          type: "area",
          fill: "gradient",
          data: allApplications.map((i) => i.count).slice(-7),
        },
      ]);
    }
  }, [projection, userEvents]);

  const chartOptions: any = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartDataState?.map((i: any) => i.fill) },
    labels: chartLabelsState,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} users`;
          }
          return y;
        },
      },
    },
  });

  const handleProjection = (e: any) => {
    setProjection(e.target.value);
  };

  return (
    <Card>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        padding="10px"
      >
        <CardHeader
          //   subheader={`(${dashboard2.signUps.monthly.change}) than last month (sign ups)`}
          title={"Applications"}
        />
        <Select value={projection} onChange={handleProjection}>
          <MenuItem value={"yearly"}>In months</MenuItem>
          <MenuItem value={"90-days"}>last 90 days</MenuItem>
          <MenuItem value={"30-days"}>last 30 days</MenuItem>
          <MenuItem value={"14-days"}>last 14 days</MenuItem>
          <MenuItem value={"7-days"}>last 7 days</MenuItem>
        </Select>
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartDataState && (
          <ReactApexChart
            type="line"
            series={chartDataState}
            options={chartOptions}
            height={364}
            width={"100%"}
          />
        )}
      </Box>
    </Card>
  );
}
